import { styled } from "@mui/material/styles";
import { Switch } from "@mui/material";

const CustomSwitch = styled(props => <Switch {...props} />)(({ theme }) => ({
  "&.MuiSwitch-root": {
    width: "68px",
    height: "49px",
  },
  "& .MuiButtonBase-root": {
    top: "6px",
    left: "6px",
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.primary.main,
    width: "18px",
    height: "18px",
    borderRadius: "4px",
  },

  "& .MuiSwitch-track": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "5px",
  },
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: "primary",
        opacity: 0.18,
      },
    },
  },
}));

export default CustomSwitch;
