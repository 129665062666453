import { useState } from "react";
import { WISIFYGYRO_SERVICE, WISIFYGYRO_XYZ } from "../../utils/embedded/utils";

export const useGyroscope = device => {
  const [characteristic, setCharacteristic] = useState(null);
  const [gyroscope, setGyroscope] = useState([]);

  //  READING DATA FROM A DEVICE'S CHARACTERISTIC

  //  The value read from each characteristic is in 8 bytes.
  //  Here, we're handling the main characteristic for each device (WISIFY_RECEIVE).
  //  Only the 4 rightmost bytes are important for us, and the information to retrieve from them is represented below.

  //  +----------+----------+----------+----------+
  //  | AAAAAAAA | AAAAAAAA | BBBBCCCC | CCCCCCCC |
  //  +----------+----------+----------+----------+
  //  |  Byte 3  |  Byte 2  |  Byte 1  |  Byte 0  |
  //  +----------+----------+----------+----------+

  //  A -> Characteristic value - to be calibrated by each device
  //  B -> State
  //  C -> Timer
  const handleChange = event => {
    // 8 bytes of data from the characteristic
    const byteArray = new Uint8Array(event.target.value.buffer);

    const x =
      -32768 * (byteArray[5] >> 7) + (byteArray[5] & 0x7f) * 256 + byteArray[4];

    const y =
      -32768 * (byteArray[3] >> 7) + (byteArray[3] & 0x7f) * 256 + byteArray[2];

    const z =
      -32768 * (byteArray[1] >> 7) + (byteArray[1] & 0x7f) * 256 + byteArray[0];

    setGyroscope(prevData => [...prevData, { x, y, z }]);
  };

  const stopGyroscope = async () => {
    if (!characteristic) return;
    try {
      await characteristic.stopNotifications();
      characteristic.removeEventListener("characteristicvaluechanged", null);
      setCharacteristic(null);
    } catch (error) {
      console.error("Error stopping gyroscope:", error);
    }
  };

  const clearGyroscope = () => setGyroscope({});

  const startGyroscope = async () => {
    try {
      const server = await device.gatt?.connect();
      const gyroService = await server?.getPrimaryService(
        WISIFYGYRO_SERVICE.toLowerCase()
      );
      const gyroCharacteristic = await gyroService?.getCharacteristic(
        WISIFYGYRO_XYZ.toLowerCase()
      );
      await gyroCharacteristic.startNotifications();
      gyroCharacteristic?.addEventListener(
        "characteristicvaluechanged",
        handleChange
      );
    } catch (err) {
      console.error(
        `Characteristic does not support notifications. Error: ${err}`
      );
    }
  };

  return {
    startGyroscope,
    stopGyroscope,
    clearGyroscope,
    gyroscope,
  };
};
