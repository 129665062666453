import {
  Button,
  Grid,
  Slider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { IconHelp } from "@tabler/icons";
import { useState } from "react";
import { useEmbedded } from "../../hooks/useEmbedded";
import { useTranslate } from "../../translations/translate";
import CustomSwitch from "../forms/theme-elements/CustomSwitch";
import PropTypes from "prop-types";
import { useToast } from "../../contexts/ToastContext";

export const EmbeddedSettings = ({ title, measurement, onClose, props }) => {
  const translate = useTranslate();
  const { showToast } = useToast();
  const { palette } = useTheme();
  const { settings, handleSaveSettings } = useEmbedded();
  const [measuringTime, setMeasuringTime] = useState(
    props?.measuring_time ?? 5
  );
  const [interleavedMode, setInterleavedMode] = useState(
    props?.interleaved_mode ?? false
  );

  const handleSave = () => {
    const newSettings = {
      ...settings,
      [measurement]: {
        measuring_time: measuringTime,
        interleaved_mode: interleavedMode,
      },
    };
    handleSaveSettings(newSettings);
    showToast({ message: "embedded_settings_saved" });
    onClose();
  };

  return (
    <Stack alignItems="center">
      <Stack padding={2} width={532} gap={4}>
        <Stack>
          <Typography
            variant="h5"
            fontSize={24}
            fontWeight={400}
            lineHeight={"32px"}
          >
            {translate(title)}
          </Typography>
          <Typography
            variant="h5"
            fontSize={16}
            fontWeight={400}
            lineHeight={"28px"}
          >
            {translate("defining_parameters")}
          </Typography>
        </Stack>
        <Stack gap={4} paddingX={6}>
          <Stack>
            <Typography
              variant="h5"
              fontSize={16}
              fontWeight={400}
              lineHeight={"28px"}
            >
              {translate("measuring_time")}
            </Typography>
          </Stack>
          <Stack>
            <Slider
              min={1}
              max={50}
              defaultValue={5}
              value={measuringTime}
              valueLabelDisplay="on"
              onChange={(_, value) => setMeasuringTime(Number(value))}
              sx={{
                "& .MuiSlider-valueLabel": {
                  backgroundColor: palette.primary.main,
                },
              }}
            />
            <Grid container direction="row">
              <Typography
                variant="h5"
                fontSize={16}
                fontWeight={400}
                lineHeight={"28px"}
                sx={{ mr: 1 }}
              >
                {translate("interleaved_mode_label")}
              </Typography>
              <Tooltip arrow title={translate("interleaved_mode_tooltip")}>
                {/* this div is needed since @table/icons are only svg icons */}
                <div style={{ display: "inline-block" }}>
                  <IconHelp
                    style={{ width: "16px", color: palette.primary.main }}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Stack
              justifyContent="center"
              alignItems="center"
              direction="row"
              width={388}
            >
              <Typography color="text.secondary" variant="body1">
                {translate("no")}
              </Typography>
              <CustomSwitch
                name="interleaved_mode"
                checked={interleavedMode}
                onChange={event => setInterleavedMode(event.target.checked)}
              />
              <Typography color="text.secondary" variant="body1">
                {translate("yes")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" gap={3}>
          <Button variant="contained" onClick={handleSave}>
            {translate("save")}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

EmbeddedSettings.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  measurement: PropTypes.oneOf(["grip_strength", "leg_strength"]).isRequired,
  props: PropTypes.shape({
    measuring_time: PropTypes.number,
    interleaved_mode: PropTypes.bool,
  }),
};
