import { useContext } from "react";
import TugContext from "../contexts/TugContext";

export const useTug = () => {
  const context = useContext(TugContext);

  if (!context) {
    throw new Error("useTUG must be used within a TUGProvider");
  }

  return context;
};
