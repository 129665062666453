import ReactApexChart from "react-apexcharts";
export const GripChart = ({ values, series }) => {
  return (
    <div id="chart" style={{ width: 400 }}>
      <ReactApexChart
        options={{
          chart: {
            id: "realtime",
            height: 350,
            type: "line",
            animations: {
              enabled: true,
              easing: "linear",
              dynamicAnimation: {
                speed: 1000,
              },
            },
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            type: "numeric",
            tickAmount: 6, // Limit the number of ticks (adjust as needed)
            labels: {
              show: true, // Hide x-axis labels
              formatter: value => `${Math.round(value)} ms`,
            },
            tooltip: {
              enabled: true,
              style: {
                fontSize: "12px", // Font size of the tooltip text
              },
            },
          },
          yaxis: {
            labels: {
              show: true,
              formatter: value => `${Math.round(value)} kgf`, // Rounds the value to the nearest integer
            },
            min: 0,
            max: Math.max(...values) + 10,
            tickAmount: 5,
          },
          legend: {
            show: false,
          },
          tooltip: {
            y: {
              formatter: value => `${value.toFixed(2)} kgf`,
            },
          },
        }}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
};
