import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";

export const TimerParagraph = ({ children }) => {
  const theme = useTheme();

  return (
    <Typography sx={{ color: theme.palette.primary.main, fontSize: "2rem" }}>
      {children}
    </Typography>
  );
};
