import {
  DEFAULT_STATE,
  DOUBLE_TAP,
  MEASUREMENT_HAS_FINISHED,
  MEASURING,
  READY_TO_MEASURE,
} from "../../utils/embedded/utils";

export const deviceStateMessages = {
  [READY_TO_MEASURE]: "status_ready_to_measure",
  [MEASURING]: "status_measuring",
  [MEASUREMENT_HAS_FINISHED]: "status_default_state",
  [DOUBLE_TAP]: "-",
  [DEFAULT_STATE]: "status_default_state",
  leg_abduction_ready: "status_leg_abduction_ready_to_measure",
  leg_adduction_ready: "status_leg_adduction_ready_to_measure",
  allResults: "status_all_results",
  measurementsComplete: "status_all_measurements",
  changeHand: "status_change_hand",
  changeLeg: "status_change_leg",
  keepHand: "status_keep_hand",
  keepLeg: "status_keep_leg",
  beforeStart: "status_before_start",
  loadingDevice: "status_loading_device",
  tugInstructions: "tug_instructions",
  legInstructions: "leg_strength_instructions",
};
