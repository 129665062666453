import { createContext, useCallback, useMemo, useState } from "react";

// Create a context for error state
export const ErrorContext = createContext();

/**
 * ErrorProvider component that provides error state and functions to manage errors.
 * @param {object} props - The props for the component.
 * @param {React.ReactNode} props.children - The child components that will consume the error context.
 * @returns {JSX.Element} The context provider wrapping the children.
 */
export const ErrorProvider = ({ children }) => {
  const [errors, setErrors] = useState({});

  /**
   * Sets an error message for a specific field inside a category.
   * If the category does not exist, it is created.
   *
   * @param {Object} prev - The previous state of errors.
   * @param {string} field - The category of the error (e.g., "physical_activity").
   * @param {string} name - The specific field inside the category (e.g., "running").
   * @param {string} error - The error message to be set.
   */
  const setError = (field, name, error) => {
    setErrors(prev => ({
      ...prev,
      [field]: {
        ...prev[field],
        [name]: error,
      },
    }));
  };

  /**
   * Clears a specific error for a field inside a category.
   * If all errors in a category are removed, the category is also deleted.
   *
   * @param {Object} prev - The previous state of errors.
   * @param {string} field - The category of the error (e.g., "physical_activity").
   * @param {string} name - The specific field inside the category (e.g., "running").
   */
  const clearError = (field, name) => {
    setErrors(prev => {
      if (!prev[field]) return prev;
      const newCategoryErrors = { ...prev[field] };
      delete newCategoryErrors[name];

      return Object.keys(newCategoryErrors).length
        ? { ...prev, [field]: newCategoryErrors }
        : (() => {
            const newErrors = { ...prev };
            delete newErrors[field];
            return newErrors;
          })();
    });
  };

  /**
   * Checks if there is an error for a given field.
   *
   * @param {Object} errors - The current state of errors.
   * @returns {(field: string) => boolean} - A function that takes a field name and returns true if an error exists.
   */
  const hasError = useCallback(
    () =>
      Object.values(errors).some(category => Object.keys(category).length > 0),
    [errors]
  );

  const values = useMemo(
    () => ({ errors, setError, clearError, hasError }),
    [errors, hasError]
  );

  return (
    <ErrorContext.Provider value={values}>{children}</ErrorContext.Provider>
  );
};
