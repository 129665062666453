import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  styled,
} from "@mui/material";

const CustomSelect = styled(({ helperText, ...props }) => (
  <FormControl fullWidth error={props?.error}>
    <InputLabel id={props.id + "-label"}>{props.label}</InputLabel>
    <Select labelId={props.id + "-label"} {...props} />
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
))();
export default CustomSelect;
