import React from "react";
import { GripStrengthContext } from "../../contexts/GripStrengthContext";

export const useGripStrength = () => {
  const context = React.useContext(GripStrengthContext);
  if (context === undefined) {
    throw new Error("useGrip must be used within a GripProvider");
  }
  return context;
};
