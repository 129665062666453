import { action, makeObservable, observable } from "mobx";
import agent from "../agent";

class PatientStore {
  all = [];
  inProgress = {
    register: false,
    update: false,
    get: false,
    getAll: false,
    getAppointments: false,
    getAppointment: false,
  };

  constructor() {
    makeObservable(this, {
      all: observable,
      inProgress: observable,
    });
  }

  register = values => {
    this.inProgress.register = true;

    values = {
      ...values,
      unique_id_country: values?.country?.code || null,
    };

    return agent.Client.add(values)
      .then(
        action(response => {
          if (response.status === 201) {
            return true;
          }
        })
      )
      .catch(
        action(err => {
          return false;
        })
      )
      .finally(
        action(() => {
          this.inProgress.register = false;
        })
      );
  };

  update = (id, values) => {
    this.inProgress.update = true;

    values = {
      person: {
        ...values,
        unique_id_country: values?.country?.code || null,
      },
      professionals: {},
      entities: {},
    };

    return agent.Client.update(id, values)
      .then(
        action(response => {
          if (response.status === 200) {
            return true;
          }
        })
      )
      .catch(
        action(err => {
          return false;
        })
      )
      .finally(
        action(() => {
          this.inProgress.update = false;
        })
      );
  };

  get = id => {
    this.inProgress.get = true;
    return agent.Client.get(id)
      .then(
        action(client => {
          return client.body;
        })
      )
      .catch(
        action(err => {
          console.error(err);
          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress.get = false;
        })
      );
  };

  delete = id => {
    this.inProgress.delete = true;
    return agent.Client.delete(id)
      .then(
        action(response => {
          return true;
        })
      )
      .catch(
        action(err => {
          return false;
        })
      )
      .finally(
        action(() => {
          this.inProgress.delete = false;
        })
      );
  };

  getAll = () => {
    this.inProgress.getAll = true;
    return agent.Client.list()
      .then(
        action(clients => {
          return clients.body;
        })
      )
      .catch(
        action(err => {
          console.error(err);
          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress.getAll = false;
        })
      );
  };

  getAppointments = id => {
    action(() => (this.inProgress.getAppointments = true));
    return agent.Client.listAppointments(id, 1, 100)
      .then(
        action(appointments => {
          return appointments.body;
        })
      )
      .catch(
        action(err => {
          console.error(err);
          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress.getAppointments = false;
        })
      );
  };

  getAppointment = id => {
    this.inProgress.getAppointment = true;
    return agent.Client.getAppointment(id)
      .then(
        action(appointment => {
          return appointment.body;
        })
      )
      .catch(
        action(err => {
          console.error(err);
          throw err;
        })
      )
      .finally(
        action(() => {
          this.inProgress.getAppointment = false;
        })
      );
  };

  getAppointmentReport = (id, type, language) => {
    return agent.Client.getAppointmentReport(id, type, language)
      .then(response => {
        // Get the PDF as a blob
        const pdfBlob = response.body;

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open the PDF in a new tab
        window.open(pdfUrl, "_blank");
      })

      .catch(
        action(err => {
          console.error(err);
          throw err;
        })
      );
  };
}

export default PatientStore;
