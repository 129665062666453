import { Box, Paper } from "@mui/material";
import PropTypes from "prop-types";
import { GripwiseLogo } from "../../assets/img/GripwiseLogo";

export function EmbeddedContainer({ children }) {
  return (
    <Box sx={{ maxWidth: 1024, m: "auto" }}>
      <GripwiseLogo width={235} height={115} />
      <Paper elevation={1} sx={{ padding: 4 }}>
        {children}
      </Paper>
    </Box>
  );
}

EmbeddedContainer.propTypes = {
  children: PropTypes.node,
};
