import { useState } from "react";
import { WISIFYPED, WISIFYPED_SERVICE } from "../../utils/embedded/utils";

export const usePedometer = device => {
  const [characteristic, setCharacteristic] = useState(null);
  const [pedometerFirst, setPedometerFirst] = useState(null);
  const [pedometerLast, setPedometerLast] = useState(null);
  const [pedometerCount, setPedometerCount] = useState(0);

  const startPedometer = async () => {
    try {
      const server = await device.gatt?.connect();
      if (!server || !device.gatt.connected) return;

      const pedometerService = await server.getPrimaryService(
        WISIFYPED_SERVICE.toLowerCase()
      );
      const characteristic = await pedometerService.getCharacteristic(
        WISIFYPED.toLowerCase()
      );
      await characteristic.startNotifications();
      setCharacteristic(characteristic);
      clearPedometer();
      characteristic.addEventListener(
        "characteristicvaluechanged",
        handleChange
      );
    } catch (err) {
      console.error(
        `Characteristic does not support notifications. Error: ${err}`
      );
    }
  };

  const stopPedometer = async () => {
    if (!characteristic) return;

    try {
      await characteristic.stopNotifications();
      characteristic.removeEventListener("characteristicvaluechanged", null);
      setCharacteristic(null);
    } catch (error) {
      console.error("Error stopping pedometer:", error);
    }
  };

  const handleChange = event => {
    const value = event.target.value;
    const byteArray =
      -32768 * (value.getUint8(1) >> 7) +
      (value.getUint8(1) & 0x7f) * 256 +
      value.getUint8(0);

    setPedometerFirst(prevFirst => {
      if (prevFirst === null) {
        return byteArray;
      } else {
        setPedometerLast(byteArray);
        setPedometerCount(byteArray - prevFirst);
        return prevFirst;
      }
    });
  };

  const clearPedometer = () => {
    setPedometerFirst(null);
    setPedometerCount(0);
  };

  return { startPedometer, stopPedometer, clearPedometer, pedometerCount };
};
