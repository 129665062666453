import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import agent from "../../agent";
import { EmbeddedSettings } from "../../components/embedded/EmbeddedSettings";
import { TakeMeasurementContent } from "../../components/embedded/TakeMeasurementContent";
import { useToast } from "../../contexts/ToastContext";
import { useGripStrength } from "../../hooks/protocols/useGripStrength";
import { useDevice } from "../../hooks/useDevice";
import useDialog from "../../hooks/useDialog";
import { useEmbedded } from "../../hooks/useEmbedded";

const TakeMeasurement = ({ hasSaveButton = true }) => {
  const { id } = useParams();
  const { disconnectDevice, steps } = useDevice();
  const { settings, hasSettingsSaved } = useEmbedded();
  const { showToast } = useToast();
  const { gripValues, updateGripMeasure } = useGripStrength();
  const { DialogComponent: SettingsDialog } = useDialog();

  const settingsDialogRef = useRef();

  const openSettingsDialog = () => {
    settingsDialogRef.current.open();
  };
  const closeSettingsDialog = () => {
    settingsDialogRef.current.close();
  };

  useEffect(() => {
    if (!hasSettingsSaved("grip_strength")) {
      openSettingsDialog();
    }
  }, []);

  useEffect(() => {
    const hasAllResults = steps.length === 6;
    if (hasAllResults) disconnectDevice();
  }, []);

  const handleSave = async () => {
    try {
      updateGripMeasure(id);
      await agent.Embedded.submitResults(gripValues);
    } catch (err) {
      showToast({ message: err, severity: "error" });
    }
  };

  return (
    <Box>
      <TakeMeasurementContent
        handleSettings={openSettingsDialog}
        hasSaveButton={hasSaveButton}
        onSave={handleSave}
      />

      <SettingsDialog ref={settingsDialogRef} maxWidth={"xl"}>
        <EmbeddedSettings
          onClose={closeSettingsDialog}
          measurement="grip_strength"
          title="grip_strength_evaluation"
          props={settings.grip_strength}
        />
      </SettingsDialog>
    </Box>
  );
};

export default TakeMeasurement;

TakeMeasurement.propTypes = {
  hasSaveButton: PropTypes.bool,
};
