import { useState, useImperativeHandle, forwardRef, useCallback } from "react";
import { Dialog, Stack } from "@mui/material";

const useDialog = () => {
  const [open, setOpen] = useState(false);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const DialogComponent = forwardRef(({ maxWidth, children, onClose }, ref) => {
    useImperativeHandle(ref, () => ({
      open: openDialog,
      close: closeDialog,
    }));

    return (
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={(_, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") return;
          closeDialog();
        }}
      >
        <Stack padding={3}>{children}</Stack>
      </Dialog>
    );
  });

  return { DialogComponent, isOpen: open };
};

export default useDialog;
