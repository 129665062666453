import { Box } from "@mui/material";
import { ukUA } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import { observer } from "mobx-react-lite";
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Toast } from "./components/material-ui/toast/Toast";
import ScrollToTop from "./components/shared/ScrollToTop";
import { ErrorProvider } from "./contexts/ErrorContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import { ToastProvider } from "./contexts/ToastContext";
import Router from "./routes/Router";
import components from "./theme/Components";
import { baselightTheme } from "./theme/DefaultColors";
import { LightThemeColors } from "./theme/LightThemeColors";
import { shadows } from "./theme/Shadows";
import typography from "./theme/Typography";
import Spinner from "./views/spinner/Spinner";

const App = observer(() => {
  // Initialize the theme. In the future, we should move it to a separate file and observe the theme changes (light to dark and vice versa)
  const themeOptions = LightThemeColors.find(
    theme => theme.name === "ORANGE_THEME"
  );
  const baseMode = {
    palette: {
      mode: "light",
    },
    shape: {
      borderRadius: 7,
    },
    shadows: shadows,
    typography: typography,
  };

  const theme = createTheme({
    ...baseMode,
    ...baselightTheme,
    ...ukUA,
    ...themeOptions,
    direction: "ltr",
  });

  theme.components = components(theme);

  return (
    <StrictMode>
      <Suspense fallback={<Spinner full={true} />}>
        <ThemeProvider theme={theme}>
          <HelmetProvider>
            <LanguageProvider>
              <ToastProvider>
                <ErrorProvider>
                  <Box sx={{ width: "100vw", height: "100vh" }}>
                    <BrowserRouter>
                      <ScrollToTop>
                        <Router />
                        <Toast />
                      </ScrollToTop>
                    </BrowserRouter>
                  </Box>
                </ErrorProvider>
              </ToastProvider>
            </LanguageProvider>
          </HelmetProvider>
        </ThemeProvider>
      </Suspense>
    </StrictMode>
  );
});

// create the root element
const root = createRoot(document.getElementById("root"));
// render the App component on the root element
root.render(<App />);
