import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useDevice } from "../../hooks/useDevice";
import { useEmbedded } from "../../hooks/useEmbedded";
import { useTranslate } from "../../translations/translate";
import CustomSwitch from "../forms/theme-elements/CustomSwitch";
import { GripChart } from "./GripChart";
import { MeasurementCounter } from "./MeasurementCounter";
import { useGripStrength } from "../../hooks/protocols/useGripStrength";
import { deviceStateMessages } from "./utils";
import {
  MEASUREMENT_HAS_FINISHED,
  MEASURING,
  READY_TO_MEASURE,
} from "../../utils/embedded/utils";
import { MeasurementInfoCard } from "../MeasurementInfoCard";

const stepsLabel = [
  "right_hand",
  "left_hand",
  "right_hand",
  "left_hand",
  "right_hand",
  "left_hand",
];
export const activeStepColor = "#353767";
export const inactiveColor = "#7C8FAC";

export const TakeMeasurementContent = ({
  handleSettings,
  onSave,
  hasSaveButton,
}) => {
  const translate = useTranslate();
  const {
    values,
    strengthTime,
    connected,
    disconnectDevice,
    resetValues,
    searchDevices,
    currVal,
    steps,
    setSteps,
    isLoadingDevice,
    device,
    deviceState,
  } = useDevice();
  const { gripSteps, setGripSteps, updateGripStrengthData } = useGripStrength();

  const hasAllResults = steps.length === 6;

  const isDeviceConnected = !!connected;
  const { settings, handleSaveSettings } = useEmbedded();
  const interleavedMode = settings.grip_strength?.interleaved_mode ?? true;
  const isRightDominant =
    settings.grip_strength?.dominant_hand === "right" ?? true;
  const [resultsWereSent, setResultsWereSent] = useState(false);
  const [dominantHandValues, setDominantHandValues] = useState(["-", "-", "-"]);
  const [nonDominantHandValues, setNonDominantHandValues] = useState([
    "-",
    "-",
    "-",
  ]);

  const adjustedSteps = useMemo(() => {
    if (interleavedMode) {
      return stepsLabel.map((_, index) =>
        (isRightDominant && index % 2 === 0) ||
        (!isRightDominant && index % 2 !== 0)
          ? "right_hand"
          : "left_hand"
      );
    } else {
      return stepsLabel.sort((a, b) => {
        if (isRightDominant) {
          return a === "right_hand" ? -1 : 1;
        } else {
          return a === "left_hand" ? -1 : 1;
        }
      });
    }
  }, [isRightDominant, interleavedMode]);

  const getStatusMessage = () => {
    /* Loading device */
    if (isLoadingDevice) return translate(deviceStateMessages.loadingDevice);

    /* Device not connected */
    if (!connected) return translate(deviceStateMessages[deviceState]);

    /* Ready to start measuring */
    if (deviceState == READY_TO_MEASURE) {
      return translate(deviceStateMessages[deviceState]);
    }

    /* Currently measuring */
    if (deviceState === MEASURING)
      return translate(deviceStateMessages[MEASURING]);

    /* Connected but hasn't started yet */
    if (connected && !steps.length)
      return translate(deviceStateMessages.beforeStart);

    /* Completed all the steps */
    if (hasAllResults) return translate(deviceStateMessages.allResults);

    if (connected && deviceState === MEASUREMENT_HAS_FINISHED) {
      if (!settings.grip_strength.interleaved_mode && steps.length !== 3) {
        return translate(deviceStateMessages.keepHand);
      } else {
        return translate(deviceStateMessages.changeHand);
      }
    }

    return translate(deviceStateMessages[deviceState]);
  };

  useEffect(() => {
    setSteps(gripSteps);

    return () => {
      disconnectDevice();
    };
  }, []);

  useEffect(() => {
    if (steps.length > 0) {
      if (steps.length > 6) {
        // do not add more values to hands
        return;
      }
      const dominantHand = ["-", "-", "-"];
      const nonDominantHand = ["-", "-", "-"];

      if (settings.grip_strength?.interleaved_mode) {
        steps.forEach((step, index) => {
          const formattedStep = step !== "-" ? step.toFixed(2) : step;
          if (index % 2 === 0 && step !== "-") {
            dominantHand[Math.floor(index / 2)] = formattedStep;
          } else if (index % 2 !== 0 && step !== "-") {
            nonDominantHand[Math.floor(index / 2)] = formattedStep;
          }
        });
      } else {
        steps.slice(0, 3).forEach((step, index) => {
          const formattedStep = step !== "-" ? step.toFixed(2) : step;
          if (step !== "-") {
            dominantHand[index] = formattedStep;
          }
        });
        steps.slice(3, 6).forEach((step, index) => {
          const formattedStep = step !== "-" ? step.toFixed(2) : step;
          if (step !== "-") {
            nonDominantHand[index] = formattedStep;
          }
        });
      }

      setDominantHandValues(dominantHand);
      setNonDominantHandValues(nonDominantHand);
    } else {
      setDominantHandValues(["-", "-", "-"]);
      setNonDominantHandValues(["-", "-", "-"]);
    }
  }, [steps]);

  useEffect(() => {
    if (!steps.length) return;

    setGripSteps(steps);

    updateGripStrengthData(
      steps.length,
      values,
      strengthTime,
      isRightDominant,
      device?.id
    );
  }, [steps]);

  const rightHandData = isRightDominant
    ? dominantHandValues
    : nonDominantHandValues;
  const rightHandMaxStrength =
    rightHandData.filter(value => value !== "-").length > 0
      ? Math.max(
          ...rightHandData.filter(value => value !== "-").map(Number)
        ).toFixed(2)
      : "-";

  const leftHandData = isRightDominant
    ? nonDominantHandValues
    : dominantHandValues;
  const leftHandMaxStrength =
    leftHandData.filter(value => value !== "-").length > 0
      ? Math.max(
          ...leftHandData.filter(value => value !== "-").map(Number)
        ).toFixed(2)
      : "-";

  const handleSave = newDominantHand => {
    const newSettings = {
      ...settings,
      dominant_hand: newDominantHand,
    };

    handleSaveSettings(newSettings);
  };

  const handleResults = async () => {
    setResultsWereSent(true);
    onSave();
  };

  return (
    <>
      <Typography variant="h4">{translate("grip_strength")}</Typography>
      <Stack
        direction="row"
        gap={useMediaQuery(theme => theme.breakpoints.down("lg")) ? 4 : 12}
        justifyContent="center"
      >
        <Stack justifyContent="space-between">
          <Stack direction="row" gap={8} marginTop={4}>
            <Stack justifyContent="flex-start">
              <Stepper activeStep={steps.length} alternativeLabel>
                {adjustedSteps.map((label, index) => (
                  <Step
                    key={index}
                    sx={{
                      "& .MuiStepLabel-label.Mui-active": {
                        color: activeStepColor,
                      },
                      "& .MuiStepIcon-root.Mui-active": {
                        color: activeStepColor,
                      },
                    }}
                  >
                    <StepLabel
                      sx={{
                        "& .MuiStepLabel-label.Mui-active": {
                          color: activeStepColor,
                        },
                        "& .MuiStepIcon-root.Mui-active": {
                          color: activeStepColor,
                        },
                      }}
                    >
                      {translate(label)}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </Stack>
          <Typography
            variant="h5"
            fontSize={16}
            fontWeight={400}
            lineHeight={"32px"}
          >
            {translate("protocol_time")} |{" "}
            <Typography variant="caption" fontSize={14}>
              {settings.grip_strength?.measuring_time ?? 5} {translate("secs")}
            </Typography>
          </Typography>

          <GripChart
            values={values}
            series={[
              {
                data: values,
              },
            ]}
          />
          <Stack direction="row" justifyContent="center" gap={2}>
            <Button
              flex={1}
              onClick={handleSettings}
              disabled={isDeviceConnected}
            >
              {translate("settings")}
            </Button>
            <Button
              flex={1}
              variant="outlined"
              color="secondary"
              onClick={resetValues}
            >
              {translate("common_restart")}
            </Button>
            <Button
              flex={1}
              onClick={isDeviceConnected ? disconnectDevice : searchDevices}
            >
              {isLoadingDevice ? (
                <CircularProgress size={24} color="secondary" />
              ) : isDeviceConnected ? (
                translate("disconnect")
              ) : (
                translate("connect")
              )}
            </Button>
          </Stack>
        </Stack>
        <Stack justifyContent={"space-evenly"} gap={4}>
          <Stack
            direction={
              useMediaQuery(theme => theme.breakpoints.down("lg"))
                ? "column"
                : "row"
            }
            justifyContent="space-between"
          >
            <Stack gap={1}>
              <Stack>
                <Typography variant="h5" fontSize={14} fontWeight={500}>
                  {translate("left_hand")}
                </Typography>
                <Stack
                  borderRadius={0.5}
                  width={220}
                  height={32}
                  direction={"row"}
                  justifyContent="space-evenly"
                  alignItems={"center"}
                  border={`1px solid ${inactiveColor}`}
                >
                  {leftHandData.map((value, index) => {
                    return (
                      <Typography key={index} variant="caption" fontSize={14}>
                        {value}
                      </Typography>
                    );
                  })}
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  color={"primary"}
                  variant="h5"
                  fontSize={14}
                  fontWeight={500}
                >
                  {translate("max_strength")}{" "}
                  <Typography
                    color={"primary"}
                    variant="span"
                    fontSize={14}
                    fontWeight={400}
                  >
                    [kgf]
                  </Typography>
                </Typography>
                <Stack
                  borderRadius={0.5}
                  width={220}
                  height={32}
                  direction={"row"}
                  justifyContent="space-around"
                  alignItems="center"
                  border={`1px solid ${inactiveColor}`}
                >
                  <Typography variant="caption" fontSize={14}>
                    {leftHandMaxStrength}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack gap={1}>
              <Stack>
                <Typography
                  color="secondary"
                  variant="h5"
                  fontSize={14}
                  fontWeight={500}
                >
                  {translate("right_hand")}
                </Typography>
                <Stack
                  borderRadius={0.5}
                  width={220}
                  height={32}
                  direction={"row"}
                  justifyContent="space-evenly"
                  alignItems={"center"}
                  border={`1px solid ${inactiveColor}`}
                >
                  {rightHandData.map((value, index) => {
                    return (
                      <Typography key={index} variant="caption" fontSize={14}>
                        {value}
                      </Typography>
                    );
                  })}
                </Stack>
              </Stack>
              <Stack>
                <Typography
                  color={"secondary"}
                  variant="h5"
                  fontSize={14}
                  fontWeight={500}
                >
                  {translate("max_strength")}{" "}
                  <Typography
                    color={"secondary"}
                    variant="span"
                    fontSize={14}
                    fontWeight={400}
                  >
                    [kgf]
                  </Typography>
                </Typography>
                <Stack
                  borderRadius={0.5}
                  width={220}
                  height={32}
                  direction={"row"}
                  justifyContent="space-around"
                  alignItems="center"
                  border={`1px solid ${inactiveColor}`}
                >
                  <Typography variant="caption" fontSize={14}>
                    {rightHandMaxStrength}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={
              useMediaQuery(theme => theme.breakpoints.down("lg"))
                ? "column"
                : "row"
            }
            gap={useMediaQuery(theme => theme.breakpoints.down("lg")) ? 2 : 10}
          >
            <Stack gap={2}>
              <Stack gap={2} flex={1}>
                {connected ? (
                  <>
                    <MeasurementCounter />
                    <Stack width={182} gap={2}>
                      <Typography
                        variant="h4"
                        fontSize={18}
                        color={"secondary"}
                      >
                        {translate("current_strength")}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontSize={32}
                        color={"secondary"}
                      >
                        {currVal}
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <MeasurementInfoCard
                    title="measurement_instructions_label"
                    body={translate("grip_strength_instructions")}
                  />
                )}
              </Stack>
              <Stack>
                <Typography variant="h4" fontSize={14} color="secondary">
                  {translate("dominant_hand")}
                </Typography>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <Typography color="secondary" variant="body1">
                    {translate("left_hand")}
                  </Typography>
                  <CustomSwitch
                    name="dominant_hand"
                    checked={isRightDominant}
                    onChange={event =>
                      handleSave(event.target.checked ? "right" : "left")
                    }
                  />
                  <Typography color="secondary" variant="body1">
                    {translate("right_hand")}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack gap={2}>
              <MeasurementInfoCard
                title="instructions_label"
                body={getStatusMessage()}
              />
              {hasSaveButton && (
                <Button onClick={handleResults}>
                  {hasAllResults
                    ? translate("send_complete_results")
                    : translate("send_partial_results")}
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

TakeMeasurementContent.propTypes = {
  handleSettings: PropTypes.func,
  onSave: PropTypes.func,
  hasSaveButton: PropTypes.bool,
};
