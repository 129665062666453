import { isEqual } from "lodash";
import { createContext, useEffect, useState } from "react";
import {
  DEFAULT_STATE,
  DOUBLE_TAP,
  MEASUREMENT_HAS_FINISHED,
  MEASURING,
  MEASURING_TIME_CODE,
  READY_TO_MEASURE,
  WISIFYACC_SERVICE,
  WISIFYBATTSERVICE,
  WISIFYGYRO_SERVICE,
  WISIFYPED_SERVICE,
  WISIFYRECEIVE,
  WISIFYSEND,
  WISIFYSERVICE,
  WISIFYTBATTRECEIVE,
} from "../utils/embedded/utils";
import { useToast } from "./ToastContext";
import {
  useAccelerometer,
  useGyroscope,
  usePedometer,
} from "../hooks/device-services";

export const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const { showToast } = useToast();

  const [device, setDevice] = useState(null);
  const [connected, setConnected] = useState(false);
  const [isLoadingDevice, setIsLoadingDevice] = useState(false);
  const [batteryLevel, setBatteryLevel] = useState(null);
  const [currVal, setCurrVal] = useState("0.0");
  const [values, setValues] = useState([]);
  const [fullValues, setFullValues] = useState([]);
  const [measurementStarted, setMeasurementStarted] = useState(false);
  const [deviceState, setDeviceState] = useState(DEFAULT_STATE);
  const [steps, setSteps] = useState([]);
  const [strengthTime, setStrengthTime] = useState([]);
  const [fullStrengthTime, setFullStrengthTime] = useState([]);

  const { startPedometer, stopPedometer, clearPedometer, pedometerCount } =
    usePedometer(device);
  const {
    startAccelerometer,
    stopAccelerometer,
    clearAccelerometer,
    accelerometer,
  } = useAccelerometer(device);
  const { startGyroscope, stopGyroscope, clearGyroscope, gyroscope } =
    useGyroscope(device);

  useEffect(() => {
    const settings = JSON.parse(
      localStorage.getItem("@gripwise_embedded_settings")
    );

    if (connected && settings) {
      updateMeasuringTime(settings.measuring_time);
    }
  }, [connected]);

  const startServices = () => {
    startPedometer();
    startAccelerometer();
    startGyroscope();
  };

  const stopServices = () => {
    stopPedometer();
    stopAccelerometer();
    stopGyroscope();
  };

  const clearServices = () => {
    clearPedometer();
    clearAccelerometer();
    clearGyroscope();
  };

  const resetValues = () => {
    setCurrVal("0.0");
    setValues([]);
    setMeasurementStarted(false);
    setSteps([]);
    setFullValues([]);
    setStrengthTime([]);
    setFullStrengthTime([]);
    clearServices();
    showToast({ message: "common_reset" });
  };

  const disconnectDevice = async () => {
    if (!device) return;
    await device.gatt.disconnect();
    setConnected(false);
    setDevice(null);
  };

  const searchDevices = async () => {
    let lastState = null;

    const options = {
      optionalServices: [
        WISIFYSERVICE.toLowerCase(),
        WISIFYACC_SERVICE.toLowerCase(),
        WISIFYGYRO_SERVICE.toLowerCase(),
        WISIFYBATTSERVICE.toLowerCase(),
        WISIFYPED_SERVICE.toLowerCase(),
      ],
      filters: [
        {
          namePrefix: "Gripwise",
        },
      ],
    };

    const calibrate = value => {
      return 0.030603 * value + 0.0089251;
    };

    try {
      setIsLoadingDevice(true);
      const device = await navigator.bluetooth.requestDevice(options);
      setDevice(device);

      if (!device.name) return;

      const server = await device.gatt?.connect();

      const updateBattery = () => {
        if (!server || !device.gatt.connected) return;

        server
          .getPrimaryService(WISIFYBATTSERVICE.toLowerCase())
          .then(service =>
            service.getCharacteristic(WISIFYTBATTRECEIVE.toLowerCase())
          )
          .then(characteristic => characteristic.readValue())
          .then(value => setBatteryLevel(value.getUint8(0)));
      };

      updateBattery();

      const strengthService = await server?.getPrimaryService(
        WISIFYSERVICE.toLowerCase()
      );

      const strengthCharacteristic = await strengthService?.getCharacteristic(
        WISIFYRECEIVE.toLowerCase()
      );
      setConnected(true);
      setIsLoadingDevice(false);

      if (strengthCharacteristic.properties.notify) {
        // Introduce a short delay before starting notifications
        await new Promise(resolve => setTimeout(resolve, 1000));
        await strengthCharacteristic.startNotifications();

        strengthCharacteristic?.addEventListener(
          "characteristicvaluechanged",
          event => {
            /*

                                    **READING DATA FROM A DEVICE'S CHARACTERISTIC**

                                    The value read from each characteristic is in 8 bytes.
                                    Here, we're handling the main characteristic for each device (WISIFY_RECEIVE).
                                    Only the 4 rightmost bytes are important for us, and the information to retrieve from them is represented below.

                                    +----------+----------+----------+----------+
                                    | AAAAAAAA | AAAAAAAA | BBBBCCCC | CCCCCCCC |
                                    +----------+----------+----------+----------+
                                    |  Byte 3  |  Byte 2  |  Byte 1  |  Byte 0  |
                                    +----------+----------+----------+----------+

                                    A -> Characteristic value - to be calibrated by each device
                                    B -> State
                                    C -> Timer

                                  */

            // 8 bytes of data from the characteristic
            const byteArray = new Int8Array(event.target.value.buffer);

            const dataView = new DataView(event.target.value.buffer);
            let value = Math.abs(dataView.getInt16(2, true)); // Assuming little-endian
            value = calibrate(value);
            // B - state
            let state = byteArray[1] & 0xf0;

            // C - timer - not used here
            let timer = ((byteArray[1] & 0x0f) << 8) | (byteArray[0] & 0xff);

            /*

                                    **DEVICE STATE MANAGEMENT**

                                    State space:
                                    - DEFAULT_STATE [1]
                                    - READY_TO_MEASURE [2]
                                    - MEASURING [3]
                                    - MEASUREMENT_HAS_FINISHED [4]
                                    - DOUBLE_TAP [5]

                                    Valid transitions:
                                    - *\{2} -> 2
                                    - {1,2} -> 3
                                    - 3 -> 3
                                    - 3 -> 4
                                    - *\{5} -> 5
                                    (any other transition won't be considered)

                                  */
            const newVal = value.toFixed(1);

            setCurrVal(newVal);

            switch (state) {
              // *\{2} -> 2
              case READY_TO_MEASURE:
                if (lastState === READY_TO_MEASURE) break;
                setDeviceState(READY_TO_MEASURE);

                break;

              case MEASURING:
                setDeviceState(MEASURING);
                setMeasurementStarted(true);
                switch (lastState) {
                  // {1,2} -> 3
                  case DEFAULT_STATE:
                  case READY_TO_MEASURE:
                    setValues([]);
                    setDeviceState(READY_TO_MEASURE);
                    setStrengthTime([]);
                    break;

                  // 3 -> 3
                  case MEASURING:
                    setValues(prevValues => [...prevValues, value]);
                    setDeviceState(MEASURING);
                    setStrengthTime(prevTimer => [...prevTimer, timer]);
                    break;

                  default:
                    break;
                }
                break;

              // 3 -> 4
              case MEASUREMENT_HAS_FINISHED:
                if (MEASUREMENT_HAS_FINISHED !== deviceState) {
                  setDeviceState(MEASUREMENT_HAS_FINISHED);
                }
                if (lastState !== MEASURING) break;
                let higherValue = 0;

                setValues(prevValues => {
                  const newValues = [...prevValues, value];
                  higherValue = Math.max(...newValues);

                  setFullValues(prevFullValues => {
                    if (prevFullValues.length === 6) {
                      return prevFullValues;
                    }
                    return prevFullValues.some(arr => isEqual(arr, newValues))
                      ? prevFullValues
                      : [...prevFullValues, newValues];
                  });
                  return newValues;
                });
                setStrengthTime(prevTimer => {
                  const test = [...prevTimer, timer];

                  setFullStrengthTime(prevFullTimer => {
                    return [...prevFullTimer, [...prevTimer, timer]];
                  });
                  return test;
                });

                setSteps(prevSteps => [...prevSteps, higherValue]);
                break;

              // *\{5} -> 5
              case DOUBLE_TAP:
                if (lastState === DOUBLE_TAP) break;
                // do nothing
                break;

              default:
                break;
            }

            lastState = state;
          }
        );
      } else {
        console.error("Characteristic does not support notifications");
      }

      setInterval(() => {
        // update battery level
        updateBattery();
      }, 10000);
    } catch (err) {
      console.error(err);
      setIsLoadingDevice(false);
    }
  };

  const updateMeasuringTime = async time => {
    const finalTime = time * 100;
    await sendValueWithCode(MEASURING_TIME_CODE, finalTime);
  };

  const sendValueWithCode = async (code, value) => {
    let valueWithCode = (code << 24) | value;

    // Create a 4-byte ArrayBuffer
    let buffer = new ArrayBuffer(4);
    let dataView = new DataView(buffer);

    // Write the 32-bit integer in little-endian format
    dataView.setInt32(0, valueWithCode, true); // true = little-endian

    // Convert the buffer to a Uint8Array for further processing
    let uint8Array = new Uint8Array(buffer);

    await sendValue(uint8Array);
  };

  const sendValue = async buffer => {
    // Ensure the device is connected
    if (!device.gatt.connected) {
      throw new Error("Device is not connected");
    }

    // Get the primary service
    const service = await device.gatt.getPrimaryService(
      WISIFYSERVICE.toLowerCase()
    );

    // Get the characteristic
    const characteristic = await service.getCharacteristic(
      WISIFYSEND.toLowerCase()
    );

    // Write the buffer value to the characteristic
    await characteristic.writeValue(buffer);
  };

  return (
    <DeviceContext.Provider
      value={{
        connected,
        measurementStarted,
        deviceState,
        device,
        batteryLevel,
        currVal,
        values,
        searchDevices,
        disconnectDevice,
        resetValues,
        steps,
        setSteps,
        isLoadingDevice,
        fullValues,
        strengthTime,
        fullStrengthTime,
        updateMeasuringTime,
        startServices,
        stopServices,
        clearServices,
        pedometerCount,
        accelerometer,
        gyroscope,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
