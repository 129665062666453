import { createContext, useMemo, useState } from "react";

/*
 * Notes:
 * - `body_part_id`: Defines which body part is being measured.
 *   - `4` → Leg Abduction
 *   - `5` → Leg Adduction
 * - `accessory`: Identifies the accessory used in the measurement.
 *   - `1` → The only accessory currently available (Leg Strength Accessory).
 */

// Initial data for previous weight step
const initialData = [
  {
    trial_1: [],
    trial_2: [],
    trial_3: [],
    trial_1_time: [],
    trial_2_time: [],
    trial_3_time: [],
    average: null,
    body_part_id: 4,
    laterality: false,
    accessory: 1,
    device_mac_address: null,
  },
  {
    trial_1: [],
    trial_2: [],
    trial_3: [],
    trial_1_time: [],
    trial_2_time: [],
    trial_3_time: [],
    average: null,
    body_part_id: 5,
    laterality: false,
    accessory: 1,
    device_mac_address: null,
  },
];

export const LegStrengthContext = createContext(null);

export const LegStrengthProvider = ({ children }) => {
  const [legStrengthData, setLegStrengthData] = useState(initialData);
  const [legSteps, setLegSteps] = useState([]);

  /**
   * Updates the measurement data for the correct trial and body part.
   * @param {number} step - The current step in the measurement process (1 to 6).
   * @param {number[]} values - The strength values array from the device.
   * @param {number[]} timer - The corresponding time values array from the device.
   */
  const updateLegStrengthData = (step, values, timer) => {
    setLegStrengthData(prev => {
      const measurementIndex = step % 2 === 1 ? 0 : 1; // 0 = Abduction, 1 = Adduction
      const trialNumber = Math.ceil(step / 2); // 1 for steps 1-2, 2 for steps 3-4, 3 for steps 5-6

      // Update the trials and times for the corresponding measurement
      const updatedData = prev.map((measurement, index) =>
        index === measurementIndex
          ? {
              ...measurement,
              [`trial_${trialNumber}`]: values,
              [`trial_${trialNumber}_time`]: timer,
              average: calculateAverage(measurement), // Recalculate average after each step
            }
          : measurement
      );

      return updatedData;
    });
  };

  // Helper function to calculate the average from the trials
  const calculateAverage = measurement => {
    const trials = [
      ...measurement.trial_1,
      ...measurement.trial_2,
      ...measurement.trial_3,
    ];

    const validValues = trials.filter(value => value !== "-");

    if (validValues.length === 0) return null;

    const sum = validValues.reduce((acc, value) => acc + parseFloat(value), 0);

    return (sum / validValues.length).toFixed(2); // Return the average value
  };

  const resetLegStrength = () => setLegStrengthData(initialData);

  const isLegEmpty = () =>
    !legStrengthData[0].trial_1.length && !legStrengthData[1].trial_1.length;

  const value = useMemo(
    () => ({
      legStrengthData,
      legSteps,
      setLegSteps,
      updateLegStrengthData,
      resetLegStrength,
      isLegEmpty,
    }),
    [legStrengthData]
  );

  return (
    <LegStrengthContext.Provider value={value}>
      {children}
    </LegStrengthContext.Provider>
  );
};
