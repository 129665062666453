import { Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslate } from "../translations/translate";

export const INACTIVE_COLOR = "#7C8FAC";

export const MeasurementInfoCard = ({ title, body }) => {
  const t = useTranslate();
  const theme = useTheme();

  return (
    <Stack gap={2}>
      <Stack width={220} borderRadius={0.5}>
        <Stack
          padding={1}
          bgcolor={theme.palette.primary.main}
          border={`1px solid ${theme.palette.primary.main}`}
          sx={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
        >
          <Typography
            variant="body1"
            fontSize={16}
            color={theme.palette.common.white}
          >
            {t(title)}
          </Typography>
        </Stack>
        <Stack
          sx={{
            border: `1px solid ${INACTIVE_COLOR}`,
            borderTop: "none",
            padding: 1,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            minHeight: 120,
            height: "fit-content",
          }}
        >
          <Typography variant="body1" color={"secondary"}>
            {body}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
