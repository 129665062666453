import React from "react";
import { LegStrengthContext } from "../../contexts/LegStrengthContext";

export const useLegStrength = () => {
  const context = React.useContext(LegStrengthContext);
  if (context === undefined) {
    throw new Error("useLegStrength must be used within a LegStrengthProvider");
  }
  return context;
};
