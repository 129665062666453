import { createContext, useMemo, useState } from "react";
import { useDevice } from "../hooks/useDevice";
import { useEmbedded } from "../hooks/useEmbedded";

export const GripStrengthContext = createContext(null);

export const GripStrengthProvider = ({ children }) => {
  const { device, fullStrengthTime, fullValues } = useDevice();
  const { settings } = useEmbedded();

  const [gripValues, setGripValues] = useState([
    {
      hand_id: 0,
      trial_1: [],
      trial_2: [],
      trial_3: [],
      trial_1_time: [],
      trial_2_time: [],
      trial_3_time: [],
      average: 0,
      device_mac_address: null,
    },
    {
      hand_id: 1,
      trial_1: [],
      trial_2: [],
      trial_3: [],
      trial_1_time: [],
      trial_2_time: [],
      trial_3_time: [],
      average: 0,
      device_mac_address: null,
    },
  ]);
  const [gripSteps, setGripSteps] = useState([]);

  const updateGripMeasure = (patient_id = 1) => {
    const timers = fullStrengthTime.filter((_, index) => index % 2 === 0);
    const payload = {
      patient_id,
      results: {
        hand_strength_measurements: [
          {
            hand_id: 0,
            trial_1: [],
            trial_2: [],
            trial_3: [],
            trial_1_time: [],
            trial_2_time: [],
            trial_3_time: [],
            average: 0,
            device_mac_address: device?.id,
          },
          {
            hand_id: 1,
            trial_1: [],
            trial_2: [],
            trial_3: [],
            trial_1_time: [],
            trial_2_time: [],
            trial_3_time: [],
            average: 0,
            device_mac_address: device?.id,
          },
        ],
      },
    };

    fullValues.forEach((value, index) => {
      const isDominantHandRight =
        settings.grip_strength.dominant_hand === "right";
      let handId;
      if (settings.grip_strength.interleaved_mode) {
        handId = isDominantHandRight ? index % 2 : index % 2 === 0 ? 1 : 0;
      } else {
        handId =
          Math.floor(index / 3) % 2 === 0
            ? isDominantHandRight
              ? 0
              : 1
            : isDominantHandRight
              ? 1
              : 0;
      }

      const trialIndex =
        (Math.floor(index / (settings.grip_strength.interleaved_mode ? 2 : 1)) %
          3) +
        1;

      payload.results.hand_strength_measurements[handId][
        `trial_${trialIndex}`
      ]?.push(value);
      payload.results.hand_strength_measurements[handId][
        `trial_${trialIndex}_time`
      ] = timers[trialIndex - 1];
    });

    payload.results.hand_strength_measurements.forEach(measurement => {
      measurement.trial_1 = measurement.trial_1.flat();
      measurement.trial_2 = measurement.trial_2.flat();
      measurement.trial_3 = measurement.trial_3.flat();

      // Calculate the average
      const trials = [
        ...measurement.trial_1,
        ...measurement.trial_2,
        ...measurement.trial_3,
      ];

      measurement.average = trials.length
        ? trials.reduce((sum, val) => Number(sum) + Number(val)) / trials.length
        : 0;
    });
    setGripValues(prev => ({ ...prev, ...payload }));
  };

  /**
   * Updates the grip strength data for the current step.
   * @param {number} step - The current step in the measurement process (1 to 6).
   * @param {number[]} values - The strength values array from the device.
   * @param {number[]} timer - The corresponding time values array from the device.
   * @param {boolean} isRightDominant - Indicates if the user is right-hand dominant.
   * @param {string} deviceId - For the device's mac address.
   */
  const updateGripStrengthData = (
    step,
    values,
    timer,
    isRightDominant,
    deviceId = null
  ) => {
    setGripValues(prev => {
      let handId;
      let trialNumber;

      if (settings.grip_strength.interleaved_mode) {
        // Determine which hand is being tested
        handId =
          step % 2 === 1 ? (isRightDominant ? 0 : 1) : isRightDominant ? 1 : 0;

        // Trial number should be determined **per hand** instead of per step
        const sameHandSteps = [...Array(step)]
          .map((_, i) =>
            i % 2 === 0 ? (isRightDominant ? 0 : 1) : isRightDominant ? 1 : 0
          )
          .filter(id => id === handId).length;

        trialNumber = sameHandSteps; // First time this hand appears = trial_1, second time = trial_2, etc.
      } else {
        // Non-interleaved mode: first 3 steps for dominant hand, last 3 for non-dominant hand
        handId =
          step <= 3 ? (isRightDominant ? 0 : 1) : isRightDominant ? 1 : 0;
        trialNumber = step <= 3 ? step : step - 3;
      }

      // Update grip values
      const updatedGripValues = prev.map((measurement, index) => {
        if (index === handId) {
          // Create the updated measurement object before calculating the average
          const updatedMeasurement = {
            ...measurement,
            [`trial_${trialNumber}`]: [
              ...(measurement[`trial_${trialNumber}`] || []),
              ...values,
            ],
            [`trial_${trialNumber}_time`]: [
              ...(measurement[`trial_${trialNumber}_time`] || []),
              ...timer,
            ],
            device_mac_address: deviceId || measurement.device_mac_address, // Preserve existing MAC if available
          };

          // Now pass the updated measurement to calculate the average
          return {
            ...updatedMeasurement,
            average: calculateAverage(updatedMeasurement), // Now includes new values
          };
        }

        return measurement;
      });

      return updatedGripValues;
    });
  };

  // Helper function to calculate the average from the trials
  const calculateAverage = measurement => {
    const trials = [
      ...(measurement.trial_1 || []),
      ...(measurement.trial_2 || []),
      ...(measurement.trial_3 || []),
    ];

    const validValues = trials.filter(value => value !== "-");

    if (validValues.length === 0) return 0; // Default to 0 if no valid values

    const sum = validValues.reduce((acc, value) => acc + parseFloat(value), 0);

    return (sum / validValues.length).toFixed(2); // Return the average value
  };

  const resetGripMeasure = () => setGripValues(null);

  const isGripEmpty = () =>
    !gripValues[0].trial_1.length && !gripValues[1].trial_1.length;

  const value = useMemo(
    () => ({
      gripValues,
      gripSteps,
      setGripSteps,
      updateGripMeasure,
      updateGripStrengthData,
      resetGripMeasure,
      isGripEmpty,
    }),
    [gripValues]
  );

  return (
    <GripStrengthContext.Provider value={value}>
      {children}
    </GripStrengthContext.Provider>
  );
};
