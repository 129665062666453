import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import agent from "../agent";
import { stores } from "../stores";

const TOKEN_STORAGE_KEY = "token";
const PROFESSIONAL_ID_STORAGE_KEY = "professionalId";
export const SETTING_STORAGE_KEY = "@gripwise_embedded_settings";

const initialSettings = {
  grip_strength: {
    measuring_time: 5,
    interleaved_mode: true,
    dominant_hand: "right",
  },
  leg_strength: {
    measuring_time: 5,
    interleaved_mode: true,
  },
};

const getInitialSettings = () => {
  const savedSettings = localStorage.getItem(SETTING_STORAGE_KEY);
  return savedSettings ? JSON.parse(savedSettings) : initialSettings;
};

export const EmbeddedContext = createContext(undefined);

export const EmbeddedProvider = ({ children }) => {
  const [settings, setSettings] = useState(getInitialSettings);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const professionalId = queryParams.get("professionalId");

  const hasSettingsSaved = ctx => {
    try {
      const storage = localStorage.getItem(SETTING_STORAGE_KEY);
      const parsed = JSON.parse(storage);
      return !!parsed[ctx];
    } catch {
      localStorage.setItem(SETTING_STORAGE_KEY, JSON.stringify(settings));
      return false;
    }
  };

  const validateToken = async (token, professionalId) => {
    try {
      if (!document.URL.includes("embedded")) return;
      const result = await agent.Embedded.validateToken(token, professionalId);
      setIsLoggedIn(!!result.body.token);
      stores.userStore.setEmbeddedToken(result.body.token);
    } catch (err) {
      navigate("/access-denied");
    }
  };

  useEffect(() => {
    stores.userStore.setEmbeddedToken(token);
    queryParams.delete(TOKEN_STORAGE_KEY);
    queryParams.delete(PROFESSIONAL_ID_STORAGE_KEY);

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      replace: true,
    });

    validateToken(token, professionalId);
  }, []);

  const handleSaveSettings = settings => {
    setSettings(settings);
    localStorage.setItem(SETTING_STORAGE_KEY, JSON.stringify(settings));
  };

  return (
    <EmbeddedContext.Provider
      value={{
        isLoggedIn,
        settings,
        handleSaveSettings,
        hasSettingsSaved,
      }}
    >
      {children}
    </EmbeddedContext.Provider>
  );
};
