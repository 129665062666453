import { Alert, Snackbar } from "@mui/material";
import { useToast } from "../../../contexts/ToastContext";

/**
 * ToastComponent displays the Snackbar (toast) notification globally.
 * It reads the toast state from the context and renders the notification.
 * @returns {JSX.Element} - The Snackbar component with the toast message.
 */
export const Toast = () => {
  const { toast, handleClose } = useToast();

  return (
    <Snackbar
      open={!!toast.message}
      autoHideDuration={toast.duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{ "& .MuiSnackbarContent-root": { minWidth: 0 } }}
    >
      <Alert
        severity={toast.severity}
        sx={{
          color: "white",
          fontWeight: "bold",
          "& .MuiAlert-icon": {
            color: "white",
          },
        }}
      >
        {toast.message}
      </Alert>
    </Snackbar>
  );
};
