import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect } from "react";
import { useDevice } from "../../hooks/useDevice";
import { useEmbedded } from "../../hooks/useEmbedded";
import useTimer from "../../hooks/useTimer";
import { useTranslate } from "../../translations/translate";
import { MEASURING, READY_TO_MEASURE } from "../../utils/embedded/utils";

export const MeasurementCounter = () => {
  const t = useTranslate();
  const { settings } = useEmbedded();
  const { deviceState } = useDevice();
  const { descriptiveTime, isRunning, handleStart, handleReset } = useTimer(
    settings.grip_strength.measuring_time * 1000,
    true
  );

  useEffect(() => {
    handleReset();
  }, [settings]);

  useEffect(() => {
    if (deviceState === MEASURING && !isRunning) {
      handleStart();
    } else if (deviceState === READY_TO_MEASURE && !isRunning) {
      handleReset();
    }
  }, [settings, deviceState]);

  return (
    <Stack width={182} gap={1}>
      <Typography variant="h4" fontSize={18} color={"secondary"}>
        {t("common_timer")}
      </Typography>
      <Typography variant="body1" fontSize={32} color={"secondary"}>
        {descriptiveTime}
      </Typography>
    </Stack>
  );
};
