import PropTypes from "prop-types";
import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useTranslate } from "../translations/translate";

/**
 * Context to share the toast notification state and functions.
 */
const ToastContext = createContext(null);

/**
 * Custom hook to access toast state and showToast function.
 * @param {string} message - The message to display in the toast.
 * @param {'success' | 'info' | 'warning' | 'error'} severity - The severity of the toast.
 * @param {number} duration - The duration the toast will be visible.
 */
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

/**
 * ToastProvider component that manages the global toast state.
 * @param {Object} props - React props for the component.
 * @param {ReactNode} props.children - Children components to wrap.
 * @returns {JSX.Element} - The ToastProvider component.
 */
export const ToastProvider = ({ children }) => {
  const translate = useTranslate();

  const [toast, setToast] = useState({
    message: "",
    severity: "success",
    duration: 6000,
  });

  //TODO: verify if an icon will be used and which color for the toast
  // right now there is not icon with an orange background and white font
  /**
   * Show a toast notification.
   * @param {string} message - The message to display in the toast.
   * @param {'success' | 'info' | 'warning' | 'error'} severity - The severity of the toast.
   * @param {number} duration - The duration the toast will be visible.
   */
  const showToast = useCallback(
    ({ message, severity = "success", duration = 6000 }) => {
      setToast({ message: translate(message), severity, duration });
    },
    [translate]
  );

  /**
   * Close the toast notification.
   */
  const handleClose = useCallback(() => {
    setToast({ message: "", severity: "info" });
  }, []);

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({ showToast, toast, handleClose }),
    [showToast, toast, handleClose]
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
