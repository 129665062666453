import { useState, useRef } from "react";

export const formatTime = milliseconds => {
  const minutes = String(Math.floor(milliseconds / 60000)).padStart(2, "0");
  const seconds = String(Math.floor((milliseconds % 60000) / 1000)).padStart(
    2,
    "0"
  );
  const centiSeconds = String(Math.floor((milliseconds % 1000) / 10)).padStart(
    2,
    "0"
  );
  return `${minutes}:${seconds}:${centiSeconds}`;
};

const useTimer = (startTime = 0, countDown = false) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(startTime);
  const [isRunning, setIsRunning] = useState(false);
  const countRef = useRef(null);

  const handleStart = () => {
    if (!countDown) {
      const startTime = Date.now() - elapsedTime;
      countRef.current = setInterval(() => {
        setElapsedTime(Date.now() - startTime);
      }, 10);
      setIsRunning(true);
    } else {
      if (remainingTime <= 0) return; // Prevent starting when time is already 0
      const endTime = Date.now() + remainingTime;
      countRef.current = setInterval(() => {
        const timeLeft = endTime - Date.now();
        if (timeLeft <= 0) {
          clearInterval(countRef.current);
          setRemainingTime(0);
          setIsRunning(false);
        } else {
          setRemainingTime(timeLeft);
        }
      }, 10);
      setIsRunning(true);
    }
  };

  const handlePause = () => {
    clearInterval(countRef.current);
    setIsRunning(false);
  };

  const handleReset = () => {
    clearInterval(countRef.current);
    setIsRunning(false);
    if (!countDown) {
      setElapsedTime(0);
    } else {
      setRemainingTime(startTime);
    }
  };

  return {
    elapsedTime,
    remainingTime,
    descriptiveTime: countDown
      ? formatTime(remainingTime)
      : formatTime(elapsedTime),
    isRunning,
    handleStart,
    handlePause,
    handleReset,
  };
};

export default useTimer;
