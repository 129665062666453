import { useParams } from "react-router-dom";
import { LegStrength } from "../../components/protocols/LegStrength";
import { Tug } from "../../components/protocols/Tug";
import Error from "../../views/pages/Error";
import { EmbeddedContainer } from "./EmbeddedContainer";
import TakeMeasurement from "./TakeMeasurement";

export function MeasurementContainer() {
  const { measurement_name } = useParams();

  const render = () => {
    switch (measurement_name) {
      case "grip":
        return <TakeMeasurement />;
      case "leg-strength":
        return <LegStrength />;
      case "tug":
        return <Tug />;
    }
  };

  if (!measurement_name) return <Error />;

  return <EmbeddedContainer>{render()}</EmbeddedContainer>;
}
