import {
    Button,
    CircularProgress,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useLegStrength } from "../../hooks/protocols/useLegStrength";
import { useDevice } from "../../hooks/useDevice";
import { useEmbedded } from "../../hooks/useEmbedded";
import useTimer from "../../hooks/useTimer";
import { useTranslate } from "../../translations/translate";
import {
    MEASUREMENT_HAS_FINISHED,
    MEASURING,
    READY_TO_MEASURE,
} from "../../utils/embedded/utils";
import { GripChart } from "../embedded/GripChart";
import { MeasurementCounter } from "../embedded/MeasurementCounter";
import { activeStepColor } from "../embedded/TakeMeasurementContent";
import { deviceStateMessages } from "../embedded/utils";
import { useParams } from "react-router-dom";
import agent from "../../agent";
import useDialog from "../../hooks/useDialog";
import { EmbeddedSettings } from "../embedded/EmbeddedSettings";
import CustomSwitch from "../forms/theme-elements/CustomSwitch";
import { MeasurementInfoCard } from "../MeasurementInfoCard";

const BORDER_COLOR = "#7C8FAC";

const LEG_STRENGTH_OPTIONS = [
  "abduction_label",
  "abduction_label",
  "abduction_label",
  "adduction_label",
  "adduction_label",
  "adduction_label",
];

const INTERLEAVED_MODE = [
  "abduction_label",
  "adduction_label",
  "abduction_label",
  "adduction_label",
  "abduction_label",
  "adduction_label",
];

export const LegStrength = () => {
  const { id } = useParams();
  const isEmbedded = document.URL.includes("embedded");

  const translate = useTranslate();
  const { legStrengthData, legSteps, setLegSteps, updateLegStrengthData } =
    useLegStrength();
  const { handleStart, isRunning, handleReset } = useTimer(5000, true);
  const { DialogComponent } = useDialog();
  const { settings, hasSettingsSaved } = useEmbedded();
  const {
    steps,
    setSteps,
    values,
    strengthTime,
    currVal,
    deviceState,
    connected,
    disconnectDevice,
    searchDevices,
    isLoadingDevice,
    resetValues,
  } = useDevice();

  const [legStrength, setLegStrength] = useState("abduction");

  const settingsDialogRef = useRef();

  const openSettingsDialog = () => {
    settingsDialogRef.current.open();
  };
  const closeSettingsDialog = () => {
    settingsDialogRef.current.close();
  };

  const getStatusMessage = () => {
    const hasAllResults = steps.length === 6;

    /* Loading device */
    if (isLoadingDevice) return translate(deviceStateMessages.loadingDevice);

    /* Device not connected */
    if (!connected) return translate(deviceStateMessages[deviceState]);

    /* Ready to start measuring */
    if (deviceState == READY_TO_MEASURE) {
      return translate(
        steps.length % 2 === 0
          ? deviceStateMessages.leg_abduction_ready
          : deviceStateMessages.leg_adduction_ready
      );
    }

    /* Currently measuring */
    if (deviceState === MEASURING)
      return translate(deviceStateMessages[MEASURING]);

    /* Connected but hasn't started yet */
    if (connected && !steps.length)
      return translate(deviceStateMessages.beforeStart);

    /* Completed all the steps */
    if (hasAllResults)
      return translate(
        isEmbedded
          ? deviceStateMessages.allResults
          : deviceStateMessages.measurementsComplete
      );

    if (connected && deviceState === MEASUREMENT_HAS_FINISHED) {
        if (!settings.interleaved_mode && steps.length !== 3) {
          return translate(deviceStateMessages.keepLeg);
        } else {
          return translate(deviceStateMessages.changeLeg);
        }
    }

    return translate(deviceStateMessages[deviceState]);
  };

  const isDeviceConnected = !!connected;
  const [abduction, setAbduction] = useState(["-", "-", "-"]);
  const [adduction, setAdduction] = useState(["-", "-", "-"]);

  useEffect(() => {
    setSteps(legSteps);

    return () => {
      disconnectDevice();
    };
  }, []);

  useEffect(() => {
    if (!hasSettingsSaved("leg_strength")) {
      openSettingsDialog();
    }

    if (deviceState === MEASURING && !isRunning) {
      handleStart();
    }

    if (deviceState === READY_TO_MEASURE && !isRunning) {
      handleReset();
    }
  }, []);

  useEffect(() => {
    if (steps.length > 0) {
      if (steps.length > 6) return;

      const abductionAux = ["-", "-", "-"];
      const adductionAux = ["-", "-", "-"];

      const options = adjustSteps();

      steps.forEach((step, index) => {
        const formattedStep = step !== "-" ? step.toFixed(2) : step;
        if (options[index] === "abduction_label") {
          abductionAux[Math.floor(abductionAux.filter(x => x !== "-").length)] =
            formattedStep;
        }
        if (options[index] === "adduction_label") {
          adductionAux[Math.floor(adductionAux.filter(x => x !== "-").length)] =
            formattedStep;
        }
      });

      setAbduction(abductionAux);
      setAdduction(adductionAux);
    } else {
      setAbduction(["-", "-", "-"]);
      setAdduction(["-", "-", "-"]);
    }

    // Update legStrengthData after each step
    if (steps.length > 0) {
      updateLegStrengthData(steps.length, values, strengthTime);
      setLegSteps(steps);
    }
  }, [disconnectDevice, steps]);

  const abductionMaxStrength =
    abduction.filter(value => value !== "-").length > 0
      ? Math.max(
          ...abduction.filter(value => value !== "-").map(Number)
        ).toFixed(2)
      : "-";

  const adductionMaxStrength =
    adduction.filter(value => value !== "-").length > 0
      ? Math.max(
          ...adduction.filter(value => value !== "-").map(Number)
        ).toFixed(2)
      : "-";

  const handleSendResults = async () => {
    const data = {
      patient_id: id,
      results: {
        leg_strength_measurements: legStrengthData,
      },
    };
    await agent.Embedded.submitResults(data);
  };

  const adjustSteps = () => {
    let arr = settings.leg_strength?.interleaved_mode
      ? [...INTERLEAVED_MODE]
      : [...LEG_STRENGTH_OPTIONS];
    if (legStrength === "adduction") arr.reverse();
    return arr;
  };

  const isLgDown = useMediaQuery(theme => theme.breakpoints.down("lg"));
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <Stack gap={3}>
      <Typography variant="h4">{translate("leg_strength")}</Typography>
      <Stack direction="row" gap={isLgDown ? 4 : 12} justifyContent="center">
        {/* grid right side */}
        <Stack direction="column" justifyContent="flex-start">
          <Stepper
            activeStep={steps.length}
            alternativeLabel
            sx={{ marginBottom: "2rem" }}
          >
            {adjustSteps().map((label, index) => (
              <Step
                key={index}
                sx={{
                  "& .MuiStepLabel-label.Mui-active": {
                    color: activeStepColor,
                  },
                  "& .MuiStepIcon-root.Mui-active": {
                    color: activeStepColor,
                  },
                }}
              >
                <StepLabel
                  sx={{
                    "& .MuiStepLabel-label.Mui-active": {
                      color: activeStepColor,
                    },
                    "& .MuiStepIcon-root.Mui-active": {
                      color: activeStepColor,
                    },
                  }}
                >
                  {translate(label)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Typography variant="h5">
            {translate("measurements")} |{" "}
            {settings.leg_strength?.measuring_time ?? 5} {translate("secs")}
          </Typography>
          <GripChart values={values} series={[{ data: values }]} />

          <Stack direction="row" justifyContent="center" gap={2}>
            <Button flex={1} onClick={openSettingsDialog} disabled={connected}>
              {translate("settings")}
            </Button>
            <Button
              onClick={isDeviceConnected ? disconnectDevice : searchDevices}
              disabled={isLoadingDevice}
            >
              {isLoadingDevice ? (
                <CircularProgress size={24} color="secondary" />
              ) : isDeviceConnected ? (
                translate("common_disconnect")
              ) : (
                translate("common_connect")
              )}
            </Button>
            <Button onClick={resetValues} disabled={!isDeviceConnected}>
              {translate("common_restart")}
            </Button>
          </Stack>
        </Stack>

        <Stack
          justifyContent="center"
          gap="2rem"
          direction={isLgDown ? (isMdDown ? "column" : "row") : "column"}
        >
          <Stack
            direction={
              useMediaQuery(theme => theme.breakpoints.down("lg"))
                ? "column"
                : "row"
            }
            justifyContent="center"
            gap={4}
          >
            <Stack gap={1} width={220}>
              <Typography variant="h5" fontSize={14}>
                {translate("leg_abduction_label")}
              </Typography>

              <Stack
                height={32}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                border={`1px solid ${BORDER_COLOR}`}
              >
                {abduction.map((value, index) => {
                  return (
                    <Typography key={index} variant="caption" fontSize={14}>
                      {value}
                    </Typography>
                  );
                })}
              </Stack>

              <Typography color="secondary" variant="h5" fontSize={14}>
                {translate("max_strength")}{" "}
                <span style={{ fontWeight: "lighter" }}>[kgf]</span>
              </Typography>

              <Stack
                borderRadius={0.5}
                height={32}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                border={`1px solid ${BORDER_COLOR}`}
              >
                <Typography variant="caption" fontSize={14}>
                  {abductionMaxStrength}
                </Typography>
              </Stack>
            </Stack>

            <Stack gap={1} width={220}>
              <Typography variant="h5" fontSize={14}>
                {translate("leg_adduction_label")}
              </Typography>

              <Stack
                borderRadius={0.5}
                height={32}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                border={`1px solid ${BORDER_COLOR}`}
              >
                {adduction.map((value, index) => {
                  return (
                    <Typography key={index} variant="caption" fontSize={14}>
                      {value}
                    </Typography>
                  );
                })}
              </Stack>

              <Typography color="secondary" variant="h5" fontSize={14}>
                {translate("max_strength")}{" "}
                <span style={{ fontWeight: "lighter" }}>[kgf]</span>
              </Typography>

              <Stack
                borderRadius={0.5}
                height={32}
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                border={`1px solid ${BORDER_COLOR}`}
              >
                <Typography variant="caption" fontSize={14}>
                  {adductionMaxStrength}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction={isLgDown ? "column" : "row"}
            justifyContent="center"
            gap={2}
            mt={2}
          >
            <Stack direction="column" gap={2}>
              {connected ? (
                <>
                  <MeasurementCounter />

                  <Stack width={182} gap={2}>
                    <Typography variant="h4" fontSize={18} color={"secondary"}>
                      {translate("current_strength")}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={32}
                      color={"secondary"}
                    >
                      {currVal}
                    </Typography>
                  </Stack>
                </>
              ) : (
                <MeasurementInfoCard
                  title="measurement_instructions_label"
                  body={translate("leg_strength_instructions")}
                />
              )}
              <Stack>
                <Typography variant="h4" ontSize={14} color="secondary">
                  {translate("start_with")}
                </Typography>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  <Typography color="secondary" variant="body1">
                    {translate("adduction_label")}
                  </Typography>
                  <CustomSwitch
                    name="leg_strength"
                    checked={legStrength === "abduction"}
                    onChange={evt =>
                      setLegStrength(
                        evt.target.checked ? "abduction" : "adduction"
                      )
                    }
                  />
                  <Typography color="secondary" variant="body1">
                    {translate("abduction_label")}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack gap={2}>
              <MeasurementInfoCard
                title="instructions_label"
                body={getStatusMessage()}
              />
              {isEmbedded && (
                <Button onClick={handleSendResults}>
                  {translate("send_results")}
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <DialogComponent ref={settingsDialogRef} maxWidth={"xl"}>
        <EmbeddedSettings
          onClose={closeSettingsDialog}
          measurement="leg_strength"
          title="leg_strength"
          props={settings.leg_strength}
        />
      </DialogComponent>
    </Stack>
  );
};
