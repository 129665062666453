import { createContext, useMemo, useState } from "react";

// Initial data for previous weight step
const initialData = {
  up_and_go_time: null,
  up_and_go_pedometer: null,
  up_and_go_accelerometer: [],
  up_and_go_gyroscope: [],
  device_mac_address: null,
};

const TugContext = createContext(null);

export const TugProvider = ({ children }) => {
  const [tugData, setTugData] = useState(initialData);

  const updateTugData = value => {
    setTugData(prev => ({
      ...prev,
      ...value,
    }));
  };

  const isTugEmpty = () =>
    !tugData.up_and_go_time && !tugData.up_and_go_pedometer;

  const resetTugData = () => setTugData(initialData);

  const value = useMemo(
    () => ({ tugData, updateTugData, resetTugData, isTugEmpty }),
    [tugData]
  );

  return <TugContext.Provider value={value}>{children}</TugContext.Provider>;
};

export default TugContext;
