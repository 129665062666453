import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import TugImg from "../../assets/img/tug.jpg";
import { useDevice } from "../../hooks/useDevice";
import useTimer, { formatTime } from "../../hooks/useTimer";
import { useTug } from "../../hooks/useTug";
import { useTranslate } from "../../translations/translate";
import { DEFAULT_STATE } from "../../utils/embedded/utils";
import { deviceStateMessages } from "../embedded/utils";
import StepsIcon from "../icons/StepsIcon";
import { TimerParagraph } from "./TimerParagraph";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import agent from "../../agent";
import { useToast } from "../../contexts/ToastContext";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { MeasurementInfoCard } from "../MeasurementInfoCard";

export const Tug = () => {
  const translate = useTranslate();
  const { id } = useParams();
  const { showToast } = useToast();
  const { tugData, updateTugData, resetTugData } = useTug();
  const {
    elapsedTime,
    descriptiveTime,
    handleStart,
    handlePause,
    isRunning,
    handleReset,
  } = useTimer();
  const {
    device,
    connected,
    disconnectDevice,
    isLoadingDevice,
    searchDevices,
    startServices,
    stopServices,
    clearServices,
    pedometerCount,
    accelerometer,
    gyroscope,
    steps,
  } = useDevice();
  const theme = useTheme();

  const getStatusMessage = () => {
    if (isLoadingDevice) return translate(deviceStateMessages.loadingDevice);
    if (!connected && steps.length === 0)
      return translate(deviceStateMessages[DEFAULT_STATE]);
    return translate(deviceStateMessages.tugInstructions);
  };

  const startTUG = () => {
    if (isRunning) {
      handlePause();
      stopServices();
      updateTugData({
        device_mac_address: device.id,
        up_and_go_time: elapsedTime,
        up_and_go_pedometer: pedometerCount,
        up_and_go_accelerometer: accelerometer,
        up_and_go_gyroscope: gyroscope,
      });
      return;
    }
    handleStart();
    startServices();
  };

  const resetTUG = () => {
    handleReset();
    clearServices();
    resetTugData();
  };

  const handleDeviceConnect = () => {
    if (connected) {
      disconnectDevice();
      handleReset();
      return;
    }
    searchDevices();
  };

  const handleSendResults = async () => {
    try {
      updateTugData(tugData);
      const payload = {
        patient_id: id,
        results: {
          tug_measurements: tugData,
        },
      };
      await agent.Embedded.submitResults(payload);
      showToast({ message: "tug_results_submitted" });
    } catch {
      showToast({ message: "tug_results_submitted_error", severity: "error" });
    }
  };

  useEffect(() => {
    return () => {
      disconnectDevice();
    };
  }, []);

  return (
    <Stack
      width="100%"
      justifyContent="center"
      alignItems="center"
      direction={useMediaQuery(theme.breakpoints.down("lg")) ? "column" : "row"}
      gap={5}
    >
      <Stack
        direction="row"
        sx={{ mt: 3, justifyContent: "center", alignItems: "center", gap: 4 }}
      >
        <Stack maxWidth={350} gap={2}>
          <Typography variant="h3">
            {translate("protocol_tug_main_title")}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {translate("protocol_tug_main_desc")}
          </Typography>

          <Typography variant="h3">{translate("common_timer")}</Typography>

          <Grid container item gap={2} direction="column">
            <Stack gap={3} direction="row" alignItems="center">
              <TimerParagraph>
                {!tugData.up_and_go_time
                  ? descriptiveTime
                  : formatTime(tugData.up_and_go_time)}
              </TimerParagraph>
              <Stack direction="row" gap={1} alignItems="center">
                <StepsIcon width="2rem" height="2rem" />
                <TimerParagraph>
                  {!tugData.up_and_go_count || isRunning
                    ? pedometerCount
                    : tugData.up_and_go_count}
                </TimerParagraph>
              </Stack>
            </Stack>

            <Grid container item gap={2}>
              <Button onClick={handleDeviceConnect}>
                {isLoadingDevice ? (
                  <CircularProgress size={24} color="secondary" />
                ) : connected ? (
                  translate("common_disconnect")
                ) : (
                  translate("common_connect")
                )}
              </Button>
              <Button onClick={startTUG} disabled={!connected}>
                {isRunning
                  ? translate("common_stop")
                  : translate("common_start")}
              </Button>
              <Button onClick={resetTUG} disabled={elapsedTime == 0}>
                {translate("common_restart")}
              </Button>
            </Grid>
          </Grid>
        </Stack>

        <Stack>
          <img
            src={TugImg}
            alt="Tug Protocol"
            width={300}
            height={300}
            style={{ borderRadius: "1rem", border: "5px solid #5722ff" }}
          />
        </Stack>
      </Stack>
      <Stack gap={2}>
        <MeasurementInfoCard
          title="instructions_label"
          body={getStatusMessage()}
        />
        {document.URL.includes("embedded") && (
          <Button onClick={handleSendResults}>
            {translate("send_results")}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
